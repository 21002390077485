/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: font(main);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }

  .split-element {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .word {
      display: flex;
      align-items: center;

      s {
        display: inline-block;
        transition-property: opacity, transform;
        transition-timing-function: ease(inout);
        transition-duration: time(fast);
        transform: translate(30px, 5px) rotate(2deg);
        opacity: 0;
      }
    }
  }
}

/** @import: Pages. */
@import "pages/login";
@import "pages/home";