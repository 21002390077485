#page-home {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    transform: translateY(100%);
    transition-property: transform;
    transition-duration: time(fast);
    transition-timing-function: ease(inout);

    &.active {
      transform: translateY(0);
      z-index: 2;
    }

    &.passed {
      transform: translateY(-100%);
      z-index: 1;

      .tabs .tab-content {
        &:last-child {
          transform: translateX(0);
        }
      }
    }

    .tabs {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .tab-content {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 1;
        transform: translateX(100%);
        transition-property: transform;
        transition-duration: time(fast);
        transition-timing-function: ease(inout);

        &.active {
          transform: translateX(0);
          z-index: 2;
        }

        &.passed {
          transform: translateX(-100%);
        }

        .flex-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          z-index: 3;
        }

        &[data-index="0"] {
          .flex-wrapper {
            padding-right: 100px;

            .image {
              position: relative;
              width: 625px;
              height: 490px;
              flex-shrink: 0;
              z-index: 1;

              .image-1 {
                position: relative;
                z-index: 2;
                opacity: 0;
                transform: translateX(-20px);
                transition-property: opacity, transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              .image-2 {
                position: absolute;
                top: calc(50% + 130px);
                left: calc(50% + 100px);
                transform: translate(-50%, -50%) scale(0);
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
                z-index: 1;
              }
            }

            .content {
              position: relative;
              margin-left: 185px;
              flex-shrink: 0;
              width: 485px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              z-index: 2;

              &-title {
                position: relative;
                left: -5px;
                margin-bottom: 30px;

                span {
                  color: black;
                  font-family: font(title);
                  font-size: 120px;
                  font-weight: 900;
                  position: relative;
                  z-index: 2;
                  display: flex;
                  justify-content: flex-end;
                  flex-wrap: unset;

                  s {
                    display: inline-block;
                    transition-property: opacity, transform;
                    transition-duration: time(fast);
                    transition-timing-function: ease(inout);
                    transform: translate(30px, 5px) rotate(2deg);
                    opacity: 0;
                  }

                  &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: black;
                    transition-property: color;
                    transition-duration: time(fast);
                    transition-timing-function: ease(inout);

                    s {
                      transform: translate(0, 0);
                    }
                  }
                }
              }

              &-description {
                width: 100%;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                p {
                  color: black;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.6;
                }
              }

              &-cta {
                margin-top: 30px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                /** Responsive. */
                @media (max-height: 700px) {
                  margin-top: 20px;
                }

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: black;
                    font-family: font(title);
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }

          .count {
            background-image: linear-gradient(to right bottom, #FEECE6, #FEFBE9);
            font-family: font(title);
            font-size: 290px;
            font-weight: 900;
            position: absolute;
            right: -30px;
            bottom: 20px;
            z-index: 1;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity: 0;
            transform: scale(0.8);
            transition-property: transform, opacity;
            transition-duration: time(fast);
            transition-timing-function: ease(inout);
          }

          &.active,
          &.passed {
            .flex-wrapper {
              .image {
                .image-1 {
                  opacity: 1;
                  transform: translateX(0);
                  transition-duration: time(slow);
                  transition-delay: .35s;
                }

                .image-2 {
                  transform: translate(-50%, -50%) scale(1);
                  transition-duration: 2s;
                  transition-delay: 1s;
                }
              }

              .content {
                &-title span {
                  s {
                    opacity: 1;
                    transform: translate(0, 0) rotate(0deg);
                    transition-duration: time(slow);

                    /** @each: Delay. */
                    @for $i from 0 to 15 {
                      &[data-index="#{$i}"] {
                        transition-delay: #{.04s * $i + .9s}, #{.04s * $i + .8s};
                      }
                    }
                  }

                  &:nth-child(2) {
                    color: white;
                    transition-duration: 2s;
                    transition-delay: 2s;

                    s {
                      transform: translate(7px, -10px);
                      transition-duration: 3s;

                      /** @each: Delay. */
                      @for $i from 0 to 15 {
                        &[data-index="#{$i}"] {
                          transition-delay: #{.04s * $i + 1.5s}, #{.04s * $i + 1.4s};
                        }
                      }
                    } 
                  }
                }

                &-description {
                  opacity: 1;
                  transition-duration: time(slow);
                  transition-delay: 1s;
                }

                &-cta {
                  opacity: 1;
                  transition-duration: time(slow);
                  transition-delay: 1.15s;
                }
              }
            }

            .count {
              opacity: 1;
              transform: scale(1);
              transition-duration: time(slow);
              transition-delay: 1.45s;
            }
          }
        }
      }
    }

    .video {
      background-color: fade-out(black, .3);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      cursor: default !important;
      transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

      &.active {
        opacity: 1;
        visibility: visible;
      }

      &-content {
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 890px;
        height: 500px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 696px;
          height: 392px;  
        }
        @media (max-width: 767px) {
          width: 300px;
          height: 169px;  
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .section-hero {
    cursor: none;
    overflow: hidden;

    * {
      cursor: none;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: opacity time(default) ease(inout);

      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .hero-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      transform: scale(1.2);
      transition: opacity time(slow) ease(inout), transform time(slow) ease(inout);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .hero-after {
      position: absolute;
      right: 0;
      bottom: -4px;
      left: 0;
      z-index: 3;
      opacity: 0;
      transition: opacity time(slow) ease(inout);

      img {
        width: 100%;
      }
    }

    .cursor {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
      pointer-events: none;
      opacity: 1;
      visibility: visible;
      transition: opacity time(default) ease(inout), visibility time(default) ease(inout);

      &.hide,
      &.removed {
        opacity: 0;
        visibility: hidden;
      }

      img {
        transform: translate(-50%, -50%);
      }
    }

    .container {
      position: relative;
      height: 100%;
      z-index: 3;

      .logo {
        position: absolute;
        top: 50px;
        left: 0;
        opacity: 0;
        transform: translateX(15px);
        transition: opacity time(default) ease(inout), transform time(default) ease(inout);
      }

      .wrapper {
        position: absolute;
        top: calc(50% + 110px);
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        &-description {
          .word {
            margin-bottom: 10px;
            overflow: hidden;

            &:nth-child(5) s {
              color: #FFC368;
            }

            &:not(:last-child) {
              margin-right: 7px;
            }

            s {
              color: white;
              font-size: 24px;
              font-weight: normal;
              opacity: 1;
              transform: translateY(-110%);

              &:not(:last-child) {
                margin-right: 2px;
              }
            }
          }
        }
      }
    }

    .keyboard {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      right: 55px;
      bottom: 55px;
      z-index: 5;
      opacity: 0;
      transition: opacity time(default) ease(inout);

      span {
        color: white;
        font-family: font(title);
        font-size: 14px;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.2;
        display: inline-block;
        margin-bottom: 15px;
      }

      &-image {
        position: relative;

        @keyframes keyboard_opacity {
          0% { opacity: .5; }
          100% { opacity: 1; }
        }
        
        img {
          &:nth-child(1) {
            position: absolute;
            bottom: 3px;
            left: 50%;
            transform: translateX(-50%);
            animation-name: keyboard_opacity;
            animation-duration: 1.5s;
            animation-timing-function: ease(inout);
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }
    }
    
    &.active,
    &.passed {
      .background {
        opacity: 1;
        transition-delay: .3s;
      }

      .hero-overlay,
      .hero-after {
        opacity: 1;
        transform: scale(1);
        transition-delay: 5s;
      }

      .container {
        .logo {
          opacity: 1;
          transform: translateX(0);
          transition-delay: 5.45s;
        }

        .wrapper {
          &-description .word {
            s {
              transform: translateY(0);
              transition-duration: time(slow);
            }

            /** @each: Delay. */
            @for $i from 0 to 7 {
              &[data-index="#{$i}"] {
                s {
                  transition-delay: #{.1s * $i + 5.5s};
                }
              }
            }
          }
        }
      }

      .keyboard {
        opacity: 1;
        transition-delay: 5.7s;
      }
    }
  }

  .section-introduction {
    .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transform-origin: 0% 50%;
      transform: translateX(-50px);;
      opacity: 0;
      transition-property: transform, opacity;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);

      img {
        position: relative;
        top: -5px;
        width: auto;
        height: calc(100% + 5px);
      }
    }

    .flex-wrapper {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      padding-right: 50px;

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        z-index: 3;
        flex-shrink: 0;

        &-title {
          position: relative;
          right: -5px;

          .word s {
            color: black;
            font-family: font(title);
            font-size: 120px;
            font-weight: 900;

            &:not(:last-child) {
              margin-right: 2px;
            }
          }
        }

        &-description {
          text-align: right;
          margin-top: 50px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          /** Responsive. */
          @media (max-height: 700px) {
            margin-top: 10px;
          }

          p {
            color: black;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.7;
          }
        }

        &-list {
          margin-top: 60px;
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          /** Responsive. */
          @media (max-height: 700px) {
            margin-top: 40px;
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              text-align: right;
              flex-shrink: 0;
              height: 90px;

              &:not(:last-child) {
                border-right: 1px solid black;
                padding-right: 20px;
                margin-right: 20px;
              }

              span {
                span {
                  color: black;
                  font-family: font(title);
                  font-size: 21px;
                  font-weight: 900;
                  line-height: 1.2;
                  display: block;

                  &:not(:last-child) {
                    font-size: 26px;
                    margin-bottom: 5px;
                  }
                }
              }

              &:nth-child(2) {
                span {
                  span {
                    color: white;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #F66602;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.active,
    &.passed {
      .background {
        opacity: 1;
        transform: translateX(0);
        transition-duration: time(slow);
        transition-delay: .35s;
      }

      .flex-wrapper {
        .content {
          &-title .word s {
            opacity: 1;
            transform: translate(0) rotate(0);
            transition-duration: time(slow);

            /** @each: Delay. */
            @for $i from 0 to 11 {
              &[data-index="#{$i}"] {
                transition-delay: #{.04s * $i + .9s}, #{.04s * $i + .8s};
              }
            }
          }

          &-subtitle,
          &-description,
          &-list {
            opacity: 1;
            transition-duration: time(slow);
          }

          &-description {
            transition-delay: 1.1s;
          }

          &-list {
            transition-delay: 1.25s;
          }
        }
      }
    }
  }

  .section-immersion {
    .tabs {
      .tab-content {

        /** Variations. */
        &[data-index="1"],
        &[data-index="2"] {
          .flex-wrapper {
            .image {
              position: relative;
              width: 405px;
              height: 665px;
              flex-shrink: 0;
              margin-left: -50px;
              z-index: 2;
              overflow: hidden;
              clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
              transition-property: clip-path;
              transition-duration: time(fast);
              transition-delay: .3s;
              transition-timing-function: ease(inout);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 0% 50%;
                transform: scaleX(.6) scaleY(1.15) translateX(-20px);
                transition-property: transform;
                transition-duration: time(fast);
                transition-delay: .3s;
                transition-timing-function: ease(inout);
              }

              &-title {
                position: absolute;
                top: 40px;
                left: 81px;
                z-index: 2;
                white-space: nowrap;
                padding-bottom: 25px;
                flex-wrap: unset;

                .word {
                  &:not(:last-child) {
                    margin-right: 20px;
                  }

                  s {
                    color: white;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: white;
                  position: absolute;
                  bottom: 0;
                  left: 150px;
                  width: 425px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }
            }

            .content {
              position: relative;
              width: 845px;
              z-index: 1;
              padding-left: 100px;
              margin-bottom: -62px;

              /** Responsive. */
              @media (max-height: 700px) {
                margin-bottom: 12px;
                padding-left: 50px;
              }

              &-title {
                position: relative;
                top: 0;
                left: -425px;
                white-space: nowrap;
                padding-bottom: 25px;
                flex-wrap: unset;

                /** Responsive. */
                @media (max-height: 700px) {
                  left: -375px;
                }

                .word {
                  &:not(:last-child) {
                    margin-right: 20px;
                  }

                  s {
                    color: black;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: black;
                  position: absolute;
                  bottom: 0;
                  left: 150px;
                  width: 425px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &-subtitle {
                margin-top: 50px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                /** Responsive. */
                @media (max-height: 700px) {
                  margin-top: 30px;
                }

                p {
                  color: black;
                  font-size: 20px;
                  font-weight: bold;
                  font-style: italic;
                  line-height: 1.5;
                }
              }

              &-cta {
                margin-top: 30px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                /** Responsive. */
                @media (max-height: 700px) {
                  margin-top: 20px;
                }

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: black;
                    font-family: font(title);
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                  }
                }
              }

              &-slide {
                position: relative;
                width: calc(100% + 120px);
                flex-shrink: 0;
                padding-top: 25px;
                height: 330px;
                margin-top: 45px;
                overflow: hidden;
                cursor: none;

                /** Responsive. */
                @media (max-height: 700px) {
                  height: 300px;
                  margin-top: 30px;
                  padding-top: 30px;
                }

                > * {
                  cursor: none;
                }

                &::after {
                  content: '';
                  background-image: linear-gradient(to left, white, fade-out(white, 1));
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 50px;
                  z-index: 2;
                  pointer-events: none;
                }

                .hover-left { left: 0; }
                .hover-right { right: 0; }
                .hover-left,
                .hover-right {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  z-index: 5;
                  width: 50%;
                }

                .cursor {
                  position: fixed;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  pointer-events: none;
                  opacity: 1;
                  visibility: visible;
                  transition-property: opacity, visibility;
                  transition-duration: time(slow);
                  transition-timing-function: ease(inout);
                  overflow: visible;

                  img {
                    transform: translate(-50%, -50%) rotate(0deg);
                    transition: transform time(fast) ease(inout);
                  }

                  .effect {
                    position: absolute;
                    top: 1px;
                    right: 0;
                    bottom: 2px;
                    left: 0;
                    border: 2px solid black;
                    border-radius: 50%;
                    transform: translate(-50%, calc(-50% - 1px));
                    transition-property: opacity, transform;
                    transition-duration: time(default);
                    transition-timing-function: ease(inout);
                    z-index: 5;

                    &.animate {
                      opacity: 0;
                      transform: translate(-50%, -50%) scale(1.3);
                    }
                  }

                  &.hide {
                    opacity: 0;
                    visibility: hidden;
                    transition-duration: time(fast);
                  }

                  &.rotate img {
                    transform: translate(-50%, -50%) rotate(-180deg);
                  }
                }

                .slide-items {
                  position: relative;
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 280px;
                  transition: transform time(default) ease(inout);

                  /** Responsive. */
                  @media (max-height: 700px) {
                    height: 220px;
                  }

                  .item {
                    background-color: #FAFAFA;
                    position: relative;
                    display: flex;
                    width: 785px;
                    height: 280px;
                    flex-shrink: 0;
                    border-radius: 5px;
                    opacity: 0;
                    transform: translateX(20px);
                    transition-property: opacity, transform;
                    transition-duration: time(fast);
                    transition-timing-function: ease(inout);

                    /** Responsive. */
                    @media (max-height: 700px) {
                      height: 220px;
                    }

                    &:not(:last-child) {
                      margin-right: 30px;
                    }

                    &-block {
                      height: 280px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      padding: 0 40px;
                      border-radius: 0 5px 5px 0;

                      /** Responsive. */
                      @media (max-height: 700px) {
                        height: 220px;
                      }

                      p {
                        color: #333333;
                      }

                      &:nth-child(1) {
                        background-color: #FF6F00;
                        position: relative;
                        flex-shrink: 0;
                        width: 305px;
                        border-radius: 5px;

                        &::before,
                        &::after {
                          position: absolute;
                          z-index: 2;
                          opacity: 0;
                          transition-property: opacity;
                          transition-duration: time(fast);
                          transition-timing-function: ease(inout);
                        }

                        &::before {
                          content: url('../../assets/images/garage/quote-1.png');
                          top: -25px;
                          left: 0;
                        }

                        &::after {
                          content: url('../../assets/images/garage/quote-2.png');
                          bottom: -20px;
                          right: -12px;
                        }

                        p {
                          color: white;
                        }
                      }
                    }

                    &-title {
                      color: white;
                      font-family: font(title);
                      font-size: 20px;
                      font-weight: 900;
                      margin-bottom: 25px;

                      /** Responsive. */
                      @media (max-height: 700px) {
                        font-size: 18px;
                      }
                    }

                    &-description p {
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 1.6;

                      /** Responsive. */
                      @media (max-height: 700px) {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .image {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              transition-duration: time(slow);
              transition-delay: .3s;

              img {
                transform: translateX(0) scaleX(1) scaleY(1);
                transition-duration: time(slow);
                transition-delay: .3s;
              }
            }

            .image .image-title,
            .content .content-title {
              &::after {
                transform: scaleX(1);
                transition-duration: time(slow);
                transition-delay: 1.2s;
              }

              .word s {
                opacity: 1;
                transform: translate(0) rotate(0);
                transition-duration: time(slow);

                /** @each: Delay. */
                @for $i from 0 to 20 {
                  &[data-index="#{$i}"] {
                    transition-delay: #{.04s * $i + .9s}, #{.04s * $i + .8s};
                  }
                }
              }
            }

            .content {
              &-subtitle,
              &-cta {
                opacity: 1;
                transition-duration: time(slow);
              }

              &-subtitle {
                transition-delay: 1.2s;
              }

              &-cta {
                transition-delay: 1.35s;
              }

              &-slide .slide-items .item {
                opacity: 1;
                transform: translateX(0);
                transition-duration: time(slow);

                &-block {
                  &::before,
                  &::after {
                    opacity: 1;
                    transition-duration: time(slow);
                    transition-delay: 2s;
                  }
                }

                /** @each: Delay. */
                @for $i from 0 to 15 {
                  &:nth-child(#{$i}) {
                    transition-delay: #{.08s * $i + 1.35s}, #{.08s * $i + 1.35s};
                  }
                }
              }
            }
          }
        }

        &[data-index="1"] {
          .flex-wrapper {
            .content {
              margin-bottom: -62px;

              /** Responsive. */
              @media (max-height: 700px) {
                margin-bottom: 12px;
              }

              &-description {
                margin-top: 100px;
              }
            }
          }
        }

        &[data-index="3"] {
          .flex-wrapper {
            &::before {
              content: '';
              background-color: #FAFAFA;
              position: absolute;
              top: 0;
              right: -12vw;
              bottom: 0;
              width: 50vw;
              z-index: 1;
            }

            &::after {
              content: '';
              background-image: url('../../assets/images/general/dots.png');
              background-repeat: repeat;
              background-position: top center;
              position: absolute;
              top: 65px;
              left: calc(50% + 220px);
              width: 60vw;
              height: 60px;
              opacity: .5;
              z-index: 3;
            }

            .left {
              position: relative;
              width: 385px;
              height: 385px;
              z-index: 2;

              &::before,
              &::after {
                position: absolute;
                z-index: 5;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
              }

              &::before {
                content: url('../../assets/images/garage/quote-1.png');
                top: -20px;
                left: -40px;
              }

              &::after {
                content: url('../../assets/images/garage/quote-2.png');
                bottom: -20px;
                right: -35px;
                transform: scale(1.5);
                filter: blur(2px);
              }

              &-line {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                &::before,
                &::after {
                  content: '';
                  background-color: black;
                  position: absolute;
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }

                &:nth-child(1) {
                  z-index: 1;

                  &::before,
                  &::after {
                    right: 0;
                    left: 0;
                    height: 6px;
                    transform: scaleX(0);
                  }

                  &::before {
                    top: 0;
                    transform-origin: 100% 50%;
                  }

                  &::after {
                    bottom: 0;
                    transform-origin: 0% 50%;
                  }
                }

                &:nth-child(2) {
                  z-index: 3;

                  &::before,
                  &::after {
                    top: 0;
                    bottom: 0;
                    width: 6px;
                    transform: scaleY(0);
                  }

                  &::before {
                    left: 0;
                    transform-origin: 50% 0%;
                  }

                  &::after {
                    right: 0;
                    transform-origin: 50% 100%;
                  }
                }
              }

              &-image {
                position: absolute;
                top: calc(50% + 20px);
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition-property: transform;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
                z-index: 2;
              }

              &-title {
                color: white;
                font-family: font(title);
                font-size: 22px;
                font-weight: bold;
                font-style: italic;
                text-align: center;
                position: absolute;
                width: 285px;
                line-height: 1.5;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);
                z-index: 5;
              }
            }

            .content {
              position: relative;
              margin-left: 315px;
              z-index: 2;

              &-description {
                position: relative;
                margin-top: 35px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                /** Responsive. */
                @media (max-height: 700px) {
                  margin-top: 25px;
                }

                p {
                  color: black;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.6;
                }
              }

              &-cta {
                margin-top: 45px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                /** Responsive. */
                @media (max-height: 700px) {
                  margin-top: 35px;
                }

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: black;
                    font-family: font(title);
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .left {
              &::before,
              &::after {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: 2s;
              }

              &-line {
                &::before,
                &::after {
                  transform: scale(1);
                  transition-duration: time(slow);
                  transition-delay: .7s;
                }
              }

              &-image {
                transform: translate(-50%, -50%) scale(1);
                transition-duration: 2s;
                transition-delay: .35s;
              }

              &-title {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: 2s;
              }
            }

            .content {
              &-description {
                opacity: 1;
                transition-duration: 2s;
                transition-delay: 1s;
              }

              &-cta {
                opacity: 1;
                transition-delay: 1.3s;
                transition-duration: time(slow);
              }
            }
          }
        }
      }
    }
  }

  .section-garage {
    .tabs {
      .tab-content {

        /** Variations. */
        &[data-index="0"] {
          .flex-wrapper .content .content-title {
            s[data-index="5"] {
              margin-right: 25px;
            }
          }
        }

        &[data-index="1"],
        &[data-index="3"],
        &[data-index="4"],
        &[data-index="5"] {
          .flex-wrapper {
            &::after {
              content: '';
              background-image: url('../../assets/images/general/dots.png');
              background-repeat: repeat;
              background-position: top center;
              position: absolute;
              top: 80px;
              width: 30vw;
              height: 90px;
              opacity: .5;
            }

            .image {
              position: relative;
              width: 405px;
              height: 665px;
              flex-shrink: 0;
              z-index: 2;
              overflow: hidden;
              clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
              transition-property: clip-path;
              transition-duration: time(fast);
              transition-delay: .3s;
              transition-timing-function: ease(inout);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 0% 50%;
                transform: scaleX(.6) scaleY(1.15) translateX(-20px);
                transition-property: transform;
                transition-duration: time(fast);
                transition-delay: .3s;
                transition-timing-function: ease(inout);
              }

              &-title {
                position: absolute;
                top: 92px;
                z-index: 2;
                white-space: nowrap;
                padding-bottom: 20px;

                .word {
                  margin-bottom: 30px;

                  &:not(:last-child) {
                    margin-right: 20px;
                  }

                  s {
                    color: white;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: white;
                  position: absolute;
                  bottom: 0;
                  width: 425px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }
            }

            .content {
              position: relative;
              width: 845px;
              z-index: 1;

              &-title {
                position: relative;
                top: 0;
                white-space: nowrap;
                padding-bottom: 20px;

                .word {
                  margin-bottom: 30px;

                  &:not(:last-child) {
                    margin-right: 20px;
                  }

                  s {
                    color: black;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: black;
                  position: absolute;
                  bottom: 0;
                  width: 425px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &-description {
                margin-top: 70px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                p {
                  color: black;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.6;
                }
              }

              &-cta {
                margin-top: 35px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: black;
                    font-family: font(title);
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .image {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              transition-duration: time(slow);
              transition-delay: .3s;

              img {
                transform: translateX(0) scaleX(1) scaleY(1);
                transition-duration: time(slow);
                transition-delay: .3s;
              }
            }

            .image .image-title,
            .content .content-title {
              &::after {
                transform: scaleX(1);
                transition-duration: time(slow);
                transition-delay: 1.2s;
              }

              .word s {
                opacity: 1;
                transform: translate(0) rotate(0);
                transition-duration: time(slow);

                /** @each: Delay. */
                @for $i from 0 to 35 {
                  &[data-index="#{$i}"] {
                    transition-delay: #{.04s * $i + .9s}, #{.04s * $i + .8s};
                  }
                }
              }
            }

            .content {
              &-description,
              &-cta {
                opacity: 1;
                transition-duration: time(slow);
              }

              &-description {
                transition-delay: 1.2s;
              }

              &-cta {
                transition-delay: 1.35s; 
              }
            }
          }
        }

        &[data-index="1"] {
          .flex-wrapper {
            &::after {
              left: calc(100% - 300px);
            }

            .image .image-title ,
            .content .content-title  {
              width: 900px;

              .word {
                &:nth-child(2) {
                  margin-right: 310px;
                }
              }
            }

            .image {
              margin-left: -50px;

              &-title {
                left: 92px;

                &::after {
                  left: 150px;
                }
              }
            }

            .content {
              padding-left: 100px;
              margin-top: -27px;

              &-title {
                left: -415px;

                &::after {
                  left: 150px;
                }
              }
            }
          }
        }

        &[data-index="2"] {
          .flex-wrapper {
            flex-direction: column;
            align-items: flex-start;

            .description {
              position: relative;
              margin-bottom: 50px;
              padding-bottom: 35px;
              opacity: 0;
              transition-property: opacity;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &::after {
                content: '';
                background-color: black;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 95px;
                z-index: 1;
              }

              p {
                color: black;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.6;
              }
            }

            .flex {
              width: 100%;
              display: flex;

              /** Responsive. */
              @media (max-height: 700px) {
                justify-content: center;
              }

              .block {
                position: relative;
                width: 320px;
                display: flex;
                flex-direction: column;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                &:not(:last-child) {
                  margin-right: 110px;

                  /** Responsive. */
                  @media (max-height: 700px) {
                    margin-right: 50px;
                  }
                }

                &-image {
                  position: relative;
                  width: 100%;
                  height: 320px;
                  border-radius: 5px;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  &--description {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 0 45px;
                    z-index: 1;
                    opacity: 1;
                    transition: opacity time(default) ease(inout);

                    p {
                      color: white;
                      font-family: font(title);
                      font-size: 16px;
                      font-weight: 900;
                      line-height: 1.6;
                    }
                  }
                }

                &-count {
                  display: flex;
                  align-items: center;

                  span {
                    &:nth-child(1) {
                      font-family: font(title);
                      font-size: 73px;
                      font-weight: 900;
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      position: relative;
                      z-index: 1;
                    }

                    &:nth-child(2) {
                      color: black;
                      font-family: font(title);
                      font-size: 32px;
                      font-weight: 900;
                      position: relative;
                      z-index: 2;
                    }
                  }
                }

                &:nth-child(1) {
                  &::after {
                    content: '';
                    background-image: url('../../assets/images/general/dots.png');
                    background-repeat: repeat;
                    background-position: top center;
                    position: absolute;
                    bottom: 30px;
                    right: -40px;
                    width: 120px;
                    height: 120px;
                    opacity: .5;
                  }

                  .block-image {
                    order: 1;
                    margin-bottom: 40px;

                    &--description {
                      background-image: linear-gradient(to right bottom, #E0C3FC, #8EC5FC);
                    }
                  }

                  .block-count {
                    order: 2;

                    span {
                      &:nth-child(1) {
                        background-image: linear-gradient(to right bottom, #BFD6FD, #E2D5FD);
                      }

                      &:nth-child(2) {
                        margin-left: -13px;
                      }
                    }
                  }
                }

                &:nth-child(2) {
                  margin-top: -50px;

                  .block-image {
                    margin-top: 40px;
                    order: 2;

                    &--description {
                      background-image: linear-gradient(to right bottom, #F9D423, #F83600);
                    }
                  }

                  .block-count {
                    order: 1;
                    flex-direction: column;
                    align-items: center;

                    span {
                      &:nth-child(1) {
                        background-image: linear-gradient(to right bottom, #FCA883, #FCE991);
                      }

                      &:nth-child(2) {
                        margin-top: -30px;
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: black;
                      }
                    }
                  }
                }

                &:nth-child(3) {
                  margin-top: -100px;

                  &::after {
                    content: '';
                    background-image: url('../../assets/images/general/dots.png');
                    background-repeat: repeat;
                    background-position: top center;
                    position: absolute;
                    top: -90px;
                    left: -40px;
                    width: 120px;
                    height: 120px;
                    opacity: .5;
                  }

                  .block-image {
                    order: 1;
                    margin-bottom: 40px;

                    &--description {
                      background-image: linear-gradient(to right bottom, #F093FB, #F5576C);
                    }
                  }

                  .block-count {
                    order: 2;
                    justify-content: flex-end;

                    span {
                      &:nth-child(1) {
                        order: 2;
                        background-image: linear-gradient(to right bottom, #F8C2ED, #FAB0C1);
                      }

                      &:nth-child(2) {
                        order: 1;
                        margin-right: -20px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .description {
              opacity: 1;
              transition-duration: time(slow);
              transition-delay: .35s;
            }

            .flex .block {
              opacity: 1;
              transition-duration: time(slow);

              &-image {
                &--description {
                  opacity: 0;
                  transition-delay: 1.5s;
                }

                &:hover {
                  .block-image--description {
                    opacity: 1;
                    transition-delay: 0s;
                  }
                }
              }

              &:nth-child(1) { transition-delay: .5s; }
              &:nth-child(2) { transition-delay: .65s; }
              &:nth-child(3) { transition-delay: .7s; }
            }
          }
        }

        &[data-index="3"] {
          .flex-wrapper {
            &::after {
              right: calc(100% - 300px);
            }

            .image .image-title ,
            .content .content-title  {
              width: 1140px;
              justify-content: flex-end;

              .word {
                &:nth-child(1) {
                  width: 87%;
                  flex-shrink: 0;
                  justify-content: flex-end;
                }

                &:nth-child(2) {
                  flex-grow: 1;
                  flex-shrink: 0;
                  justify-content: flex-end;
                }
              }
            }

            .image {
              order: 2;
              margin-right: -50px;

              &-title {
                text-align: right;
                right: 70px;

                &::after {
                  right: 50px;
                }
              }
            }

            .content {
              text-align: right;
              padding-right: 100px;
              margin-top: 27px;

              &-title {
                right: -42px;

                .word {
                  &:nth-child(1) {
                    width: 87% !important;
                  }
                }

                &::after {
                  right: 50px;
                }
              }
            }
          }
        }

        &[data-index="4"] {
          .flex-wrapper {
            &::after {
              left: calc(100% - 300px);
            }

            .image .image-title ,
            .content .content-title  {
              width: 900px;

              .word {
                &:nth-child(4) {
                  margin-right: 310px;
                }
              }
            }

            .image {
              margin-left: -50px;

              &-title {
                left: 92px;

                &::after {
                  left: 150px;
                }
              }
            }

            .content {
              padding-left: 100px;
              margin-top: 51px;

              &-title {
                left: -415px;

                &::after {
                  left: 150px;
                }
              }
            }
          }
        }

        &[data-index="5"] {
          .flex-wrapper {
            &::after {
              right: calc(100% - 300px);
            }

            .image .image-title ,
            .content .content-title  {
              width: 370px;
              justify-content: flex-end;

              .word {
                &:nth-child(2) {
                  margin-right: 0;
                }
              }
            }

            .image {
              order: 2;
              margin-right: -50px;

              &-title {
                text-align: right;
                right: 320px;

                &::after {
                  right: 50px;
                }
              }
            }

            .content {
              width: 985px;
              text-align: right;
              padding-right: 70px;
              margin-top: 51px;

              &-title {
                right: -155px;
                margin: 0 0 0 auto;

                &::after {
                  right: 50px;
                }
              }
            }
          }
        }

        &[data-index="6"],
        &[data-index="7"],
        &[data-index="8"],
        &[data-index="9"] {
          .flex-wrapper {
            justify-content: flex-end;
            padding-right: 100px;

            .content {
              &-title {
                color: white;
                font-family: font(title);
                font-size: 35px;
                font-weight: 900;
                text-transform: uppercase;
                letter-spacing: 3px;
                line-height: 1.2;
                position: relative;
                -webkit-text-fill-color: white;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: black;
                margin-bottom: 50px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);


                &::before {
                  content: '';
                  background-image: url('../../assets/images/general/dots.png');
                  background-repeat: repeat;
                  background-position: top center;
                  position: absolute;
                  width: 105px;
                  height: 105px;
                  opacity: .5;
                  z-index: -1;
                }
              }

              &-description {
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                p {
                  color: black;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.6;

                  &:not(:last-child) {
                    margin-bottom: 30px;
                  }
                }
              }
            }

            .listing {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;

              .item {
                position: relative;

                &:not(:last-child) {
                  margin-bottom: 17px;
                }

                &-image {
                  transform: scale(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
                
                &-content {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 0;
                  left: 0;
                  opacity: 0;
                  transition-property: opacity;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);

                  span {
                    color: white;
                    display: block;

                    &:nth-child(1) {
                      font-family: font(title);
                      font-size: 30px;
                      font-weight: 900;
                      margin-bottom: 10px;

                      span {
                        display: inline-block;
                        position: relative;
                        top: -5px;
                        font-size: 16px;
                        margin-bottom: -5px;
                      }
                    }

                    &:nth-child(2) {
                      font-size: 16px;
                      font-weight: normal;
                      line-height: 1.6;
                    }
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .content {
              &-title {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: .5s;
              }

              &-description {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: .65s;
              }
            }

            .listing .item {
              &-image {
                transform: scale(1);
                transition-duration: time(slow);
              }

              &-content {
                opacity: 1;
                transition-duration: time(slow); 
              }

              &:nth-child(1) {
                .item-image { transition-delay: .5s; }
                .item-content { transition-delay: 1.1s; }
              }
              &:nth-child(2) {
                .item-image { transition-delay: .6s; }
                .item-content { transition-delay: 1.2s; }
              }
              &:nth-child(3) {
                .item-image { transition-delay: .7s; }
                .item-content { transition-delay: 1.3s; }
              }
            }
          }
        }

        &[data-index="6"],
        &[data-index="8"] {
          .flex-wrapper {
            .content {
              margin-right: 90px;

              &-title {
                text-align: left;

                span {
                  margin-right: 17px;
                }
                
                &::before {
                  top: -55px;
                  left: -55px;
                }
              }

              &-description {
                text-align: left;
              }
            }

            .listing .item {
              text-align: right;

              &-content {
                right: 85px;
              }
            }
          }
        }

        &[data-index="7"],
        &[data-index="9"] {
          .flex-wrapper {
            .content {
              order: 2;
              margin-left: 90px;

              &-title {
                text-align: right;

                span {
                  margin-right: 17px;
                }
                
                &::before {
                  top: -55px;
                  right: -55px;
                }
              }

              &-description {
                text-align: right;
              }
            }

            .listing {
              order: 1;

              .item {
                text-align: left;

                &-content {
                  left: 85px;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-consolidate {
    .tabs {
      .tab-content {

        /** Variations. */
        &[data-index="0"] {
          .flex-wrapper {
            padding-right: 210px;

            .image {
              .image-1 {
                transform: unset;
              }

              .image-2 {
                top: calc(50% - 30px);
                left: calc(50% + 70px);
              }
            }

            .content {
              align-items: flex-start;
              margin-left: 50px;
            }
          }

          &.active,
          &.passed {
            .flex-wrapper .image img {
              opacity: 1;
              transition-delay: .35s;
            }
          }
        }

        &[data-index="1"] {
          .flex-wrapper {
            &::after {
              content: '';
              background-image: url('../../assets/images/general/dots.png');
              background-repeat: repeat;
              background-position: top center;
              position: absolute;
              top: 80px;
              left: calc(100% - 300px);
              width: 30vw;
              height: 90px;
              opacity: .5;
            }

            .image {
              position: relative;
              width: 405px;
              height: 665px;
              margin-left: -50px;
              flex-shrink: 0;
              z-index: 2;
              overflow: hidden;
              clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
              transition-property: clip-path;
              transition-duration: time(fast);
              transition-delay: .3s;
              transition-timing-function: ease(inout);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: 0% 50%;
                transform: scaleX(.6) scaleY(1.15) translateX(-20px);
                transition-property: transform;
                transition-duration: time(fast);
                transition-delay: .3s;
                transition-timing-function: ease(inout);
              }

              &-title {
                position: absolute;
                top: 52px;
                left: 107px;
                z-index: 2;
                white-space: nowrap;
                padding-bottom: 50px;

                .word {
                  margin-bottom: 15px;

                  &:nth-child(1) {
                    margin-right: 200px;
                  }

                  s {
                    color: white;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: white;
                  position: absolute;
                  bottom: 0;
                  width: 425px;
                  left: 150px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }
            }

            .content {
              position: relative;
              width: 845px;
              padding-left: 100px;
              margin-top: -168px;
              z-index: 1;

              &-title {
                position: relative;
                top: 0;
                left: -400px;
                white-space: nowrap;
                padding-bottom: 50px;

                .word {
                  margin-bottom: 15px;

                  &:nth-child(1) {
                    margin-right: 200px;
                  }

                  s {
                    color: black;
                    font-family: font(title);
                    font-size: 90px;
                    font-weight: 900;

                    &:not(:last-child) {
                      margin-right: 2px;
                    }
                  }
                }

                &::after {
                  content: '';
                  background-color: black;
                  position: absolute;
                  bottom: 0;
                  width: 425px;
                  left: 150px;
                  height: 2px;
                  transform-origin: 50% 50%;
                  transform: scaleX(0);
                  transition-property: transform;
                  transition-duration: time(fast);
                  transition-timing-function: ease(inout);
                }
              }

              &-description {
                margin-top: 70px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                p {
                  color: black;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 1.6;

                  &:not(:last-child) {
                    margin-bottom: 30px;
                  }
                }
              }

              &-cta {
                margin-top: 50px;
                opacity: 0;
                transition-property: opacity;
                transition-duration: time(fast);
                transition-timing-function: ease(inout);

                a {
                  display: inline-flex;
                  align-items: center;

                  span {
                    color: black;
                    font-family: font(title);
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    position: relative;
                    top: 1px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .image {
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              transition-duration: time(slow);
              transition-delay: .3s;

              img {
                transform: translateX(0) scaleX(1) scaleY(1);
                transition-duration: time(slow);
                transition-delay: .3s;
              }
            }

            .image .image-title,
            .content .content-title {
              &::after {
                transform: scaleX(1);
                transition-duration: time(slow);
                transition-delay: 1.2s;
              }

              .word s {
                opacity: 1;
                transform: translate(0) rotate(0);
                transition-duration: time(slow);

                /** @each: Delay. */
                @for $i from 0 to 25 {
                  &[data-index="#{$i}"] {
                    transition-delay: #{.04s * $i + .9s}, #{.04s * $i + .8s};
                  }
                }
              }
            }

            .content {
              &-description {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: .8s;
              }

              &-cta {
                opacity: 1;
                transition-duration: time(slow);
                transition-delay: .95s;
              }
            }
          }
        }

        &[data-index="2"] {
          .flex-wrapper {
            .item {
              position: relative;
              width: 520px;
              opacity: 0;
              transition-property: opacity;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              &:not(:last-child) {
                margin-right: 40px;
              }

              span {
                color: black;
                font-family: font(title);
                font-size: 21px;
                font-weight: 900;
                text-align: center;
                display: block;
                margin-bottom: 20px;
              }
            }

            .image {
              height: 280px;
              overflow: hidden;
              box-shadow: 0 5px 30px rgba(0, 0, 0, .1);
              border-radius: 10px;

              img {
                max-width: 100%;
              }
            }
          }

          &.active .flex-wrapper,
          &.passed .flex-wrapper {
            .item {
              opacity: 1;
              transition-duration: time(slow);
              transition-delay: .3s;

              &:nth-child(2) {
                transition-delay: .45s;
              }
            }
          }
        }
      }
    }
  }

  .navigation {
    background-color: #F66602;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    z-index: 100;
    padding: 35px 0 50px;
    transform: translateX(-100%);
    transition: transform time(slow) ease(inout);
    pointer-events: none;

    &.active {
      transform: translateX(0);
      pointer-events: initial;
    }

    &-logo {
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 50px;
    }

    &-listing {
      background-color: #F66602;
      display: flex;
      align-items: center;
      width: 85vh;
      height: 60px;
      position: absolute;
      top: 93vh;
      left: 30px;
      transform-origin: 0% 50%;
      transform: translateX(0%) rotate(-90deg);

      .item {
        position: relative;
        width: 33.33%;
        flex-shrink: 0;
        cursor: pointer;

        &-title {
          color: white;
          font-family: font(title);
          font-size: 16px;
          font-weight: 900;
          white-space: nowrap;
          padding-bottom: 9px;
          border-bottom: 1px solid #FAAE78;
          text-align: right;

          /** Responsive. */
          @media (max-height: 630px) {
           font-size: 12px;
          }
        }

        &-progress {
          background-color: #F58C38;
          position: absolute;
          right: 0;
          bottom: -1px;
          left: 0;
          height: 4px;
          transform-origin: 100% 100%;
          transform: scaleX(0);
          transition: transform time(default) ease(inout);
          z-index: 4;

          &--line {
            background-color: #FFBA00;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 0;
            transition: width time(slow) ease(inout);
          }
        }

        &.active {
          .item-progress {
            transform: scaleX(1);
          }
        }

        &.passed {
          .item-progress {
            transform: scaleX(1) scaleY(.5);
          }
        }
      }
    }
  }
}

.overlay {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(slow);
  transition-timing-function: ease(inout);

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  span {
    color: white;
    font-family: font(title);
    font-size: 20px;
    font-weight: 900;
    line-height: 1.6;
    text-align: center;
    display: inline-block;
  }
}