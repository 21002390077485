#page-login {
  background-color: black;

  .section-login {
    position: relative;
    width: 100%;
    height: 100vh;

    .logged,
    .error {
      background-color: fade-out(black, .2);
      color: white;
      font-family: font(title);
      font-size: 16px;
      font-weight: 900;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 50;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: opacity time(slow) ease(inout), visibility time(slow) ease(inout);

      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100vh;

      &-title {
        color: white;
        font-family: font(title);
        font-size: 21px;
        font-weight: 900;
        margin: 55px 0 15px;
      }

      &-description {
        margin: 0 0 35px;
        text-align: center;

        p {
          color: white;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.6;
        }
      }

      &-form {
        position: relative;
        width: 320px;

        .field-group {
          position: relative;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 25px;
          }

          .field {
            background-color: transparent;
            color: white;
            font-size: 16px;
            font-weight: normal;
            height: 50px;
            padding: 0 20px;
            display: block;
            width: 100%;
            border: 1px solid white;
            border-radius: 5px;

            &::placeholder {
              color: white;
            }
          }

          span {
            background-color: red;
            color: white;
            font-family: font(title);
            font-size: 14px;
            font-weight: 900;
            text-align: center;
            position: absolute;
            top: 50%;
            width: 150px;
            height: 30px;
            left: calc(100% + 25px);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transform: translate(10px, -50%);
            transition: opacity time(default) ease(inout), transform time(default) ease(inout);

            &.active {
              opacity: 1;
              transform: translate(0, -50%);
            }

            &::before {
              content: '';
              position: absolute;
              top: 8px;
              left: -5px;
              width: 1px;
              height: 1px;
              border-top: 5px solid transparent;
              border-right: 5px solid red;
              border-bottom: 5px solid transparent;
            }
          }
        }

        .form-submit {
          width: 100%;

          button {
            background-color: white;
            color: black;
            font-family: font(title);
            font-size: 14px;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 3px;
            display: block;
            width: 100%;
            height: 50px;
            border: 1px solid white;
            border-radius: 5px;
            transition: background-color time(default) ease(inout), color time(default) ease(inout);

            &:hover {
              background-color: black;
              color: white;
            }
          }
        }

        .message {
          color: red;
          font-size: 16px;
          font-weight: normal;
          white-space: nowrap;
          position: absolute;
          top: calc(100% + 30px);
          left: 50%;
          transition: translate(-50%, 10px);
          opacity: 0;
          transform: translateY(10px);
          transition: opacity time(default) ease(inout), transform time(default) ease(inout);

          &.active {
            opacity: 1;
            transition: translate(-50%, 0);
          }
        }
      }
    }
  }
}