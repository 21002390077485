
/** Import Heebo. */
@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

/** Import Gilroy. */
@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Heavy.woff2') format('woff2'),
       url('../assets/fonts/Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}